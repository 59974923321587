<template>
<div>
  <b-modal
    v-model="disableButton"
    centered
    no-close-on-backdrop
    hide-footer
    hide-header>
    <div class="d-flex flex-column align-items-center justify-content-center py-3">
      <b-spinner
        medium
        variant="primary"
        label="Spinning"></b-spinner>
      <p class="mt-3">Processing Delivery Order</p>
    </div>
  </b-modal>
  <b-modal
    v-model="doModal"
    title="Upload File DO "
    :active.sync="doModal">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="upload-border">
            <feather-icon
              icon="UploadIcon"
              svgClasses="h-12 w-12" /><br />
            <b-form-file
              v-model="file"
              plain
              accept=".xls,.xlsx,.csv"
              @change="previewFilesDO"
              style="margin-top:10px; margin-bottom:10px;"></b-form-file><br />
            <strong v-if="errorMessage" style="color:red; text-align:right;">{{ errorMessage }}</strong>
            <strong v-if="doUpload.sapDo!=undefined" style="text-align:right;">SAP DO: {{ doUpload.sapDo }}</strong>
            <br />
            <strong v-if="doUpload.sapDo!=undefined" style="text-align:right;">SAP SO: {{ doUpload.sapSo }}</strong>
            <br />
            <strong v-if="doUpload.sapDo!=undefined" style="text-align:right;">Delivery Date: {{ dates(doUpload.deliveryDate) }}</strong>
          </div>
        </b-col>
      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <b-button
          :disabled="doUpload.sapDo==undefined"
          v-show="!disableButton"
          variant="success"
          class="ml-1"
          @click="uploadDataDO">Start Upload</b-button>
        <!-- <b-button
   v-show="!loading"
   variant="danger"
   class="ml-1"
   @click="modalArrived = false"
            >No</b-button
          > -->
      </div>
    </template>
  </b-modal>
  <b-modal
    v-model="doDetailModal"
    title="Upload File Item List "
    :active.sync="doDetailModal">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="upload-border">
            <feather-icon
              icon="UploadIcon"
              svgClasses="h-12 w-12" /><br />
            <b-form-file
              v-model="file"
              plain
              accept=".xls,.xlsx,.csv"
              @change="previewFilesDODetails"
              style="margin-top:10px; margin-bottom:10px;"></b-form-file><br />
            <strong v-if="errorList.length>0" style="color:red; text-align:right;">{{ errorList }}</strong>

          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="doDetailFields"
            :items="doDetailUploadList"
            show-empty
            responsive>
          </b-table>
        </b-col>

      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <b-button
          :disabled="!doDetailCanUpload"
          v-show="!disableButton"
          variant="success"
          class="ml-1"
          @click="uploadDoDetail">Start Upload</b-button>
        <!-- <b-button
   v-show="!loading"
   variant="danger"
   class="ml-1"
   @click="modalArrived = false"
            >No</b-button
          > -->
      </div>
    </template>
  </b-modal>

  <b-modal
    v-model="inventoryModal"
    title="Upload File Invetory List "
    :active.sync="inventoryModal">
    <b-container>
      <b-row>
        <b-col cols="12">
          <div class="upload-border">
            <feather-icon
              icon="UploadIcon"
              svgClasses="h-12 w-12" /><br />
            <b-form-file
              v-model="file"
              plain
              accept=".xls,.xlsx,.csv"
              @change="previewFilesInventory"
              style="margin-top:10px; margin-bottom:10px;"></b-form-file><br />
            <strong v-if="errorList.length>0" style="color:red; text-align:right;">{{ errorList }}</strong>

          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-table
            :fields="inventoryFields"
            :items="inventoryUploadList"
            show-empty
            responsive>
          </b-table>
        </b-col>

      </b-row>
    </b-container>

    <template #modal-footer>
      <div class="w-100 d-flex justify-content-center align-items-center">
        <b-button
          :disabled="!inventoryCanUpload"
          v-show="!disableButton"
          variant="success"
          class="ml-1"
          @click="uploadInventory">Start Upload</b-button>
        <!-- <b-button
   v-show="!loading"
   variant="danger"
   class="ml-1"
   @click="modalArrived = false"
            >No</b-button
          > -->
      </div>
    </template>
  </b-modal>
  <b-card
    title=" "
    no-body>
    <b-card-header>
      <b-col
        cols="12"
        class="mt-2">
        <h4>Create Manual by Upload</h4>
      </b-col>

    </b-card-header>
    <b-card-body>
      <b-col
        cols="12"
        class="mt-2">
        <b-row>
          <b-col cols="6">
            <!-- Delivery Order Search & Upload Section-->
            <b-form-input
              :disabled="!uploadEnabled.do"
              v-model="doSearch"
              type="search"
              placeholder="Search by DO" />
          </b-col>
          <b-col cols="2">
            <!-- Delivery Order Search & Upload Section-->
            <b-button
              variant="primary"
              :disabled="!uploadEnabled.do"
              @click="searchDoByNumber">Search</b-button>
          </b-col>
          <b-col cols="2">
            <!-- Delivery Order Search & Upload Section-->
            <b-button
              variant="success"
              :disabled="!uploadEnabled.do"
              @click="doModal=true">Upload</b-button>
          </b-col>
          <b-col cols="2">
            <!-- Delivery Order Search & Upload Section-->
            <b-button
              variant="dark"
              @click="doRefresh">Refresh</b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        class="mt-2">
        <b-row>
          <!-- Delivery Order Detail Section-->
          <b-col cols="12">
            <div class="d-flex flex-column">
              <label>SAP DO Number</label>
              <p class="font-weight-bold">{{ currentDo.sapDo }}</p>
            </div>
          </b-col>

          <b-col cols="12">
            <div class="d-flex flex-column">
              <label>SAP SO Number</label>
              <p class="font-weight-bold">{{ currentDo.sapSo }}</p>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="d-flex flex-column">
              <label>Delivery Date</label>
              <p class="font-weight-bold">{{ dates(currentDo.deliveryDate) }}</p>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        class="mt-2">
        <!-- Solid divider -->
        <hr class="solid">
      </b-col>
      <b-col
        cols="12"
        class="mt-2">
        <b-row>
          <b-col cols="10">
            <h4>DO Item List</h4>
          </b-col>
          <b-col cols="2">
            <!-- Delivery Order Item Upload Section-->
            <b-button
              :disabled="!uploadEnabled.doDetail"
              variant="success"
              @click="doDetailModal=true"> Upload Item</b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col
        cols="12"
        class="mt-2">
        <b-row>
          <b-col cols="12">
            <b-table
              :fields="doDetailFields"
              :items="currentDo.DO_details"
              :busy.sync="tableLoading"
              show-empty
              responsive>
              <template #cell(material)="row">
                {{ getItem(row.item.material).itemModel }}
              </template>
              <template #cell(desc)="row">
                {{ getItem(row.item.material).description }}
              </template>
            </b-table>
          </b-col>

          <!--
        <b-col cols="12">
          <b-pagination
            :total-rows="totalRows"
            :per-page="perPage"
            @change="onPageChange"
            v-model="currentPage"
            style="float: right"
          />
        </b-col>
        -->
        </b-row>
      </b-col>

      <b-col cols="12">
        <!-- Solid divider -->
        <hr class="solid">
      </b-col>
      <b-col
        cols="12"
        class="mt-2">
        <b-row>
          <b-col cols="10">
            <h4>DO Inventory List</h4>
          </b-col>
          <b-col cols="2">
            <!-- Delivery Order Inventory Upload Section-->
            <b-button
              variant="success"
              :disabled="!uploadEnabled.inventory"
              @click="inventoryModal=true"> Upload Inventory</b-button>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        class="mt-2">
        <b-row>
          <b-col cols="12">
            <!-- Delivery Order Inventory Section-->
            <b-table
              :fields="inventoryFields"
              :items="inventoryList"
              :busy.sync="tableLoading"
              show-empty
              responsive>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-card-body>
  </b-card>
</div>
</template>

<script>
import {
  mapActions
} from 'vuex';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as XLSX from "xlsx";
import axios from "@/axios";
import {
  userAccess,
  dateFormat,
} from "@/utils/utils.js";
// import { isRaw } from "@vue/composition-api";

export default {
  components: {
    "date-picker": DatePicker,
    "v-select": vSelect,
  },
  data() {
    return {
      //form to save data
      currentDo: {
        deliveryDate: 0,
        DO_details: []
      },
      inventoryList: [],
      doSearch: "",

      //to upload Data
      doUpload: {},
      doDetailUploadList: [],
      inventoryUploadList: [],
      errorList: [],

      //upload modals
      doModal: false,
      doDetailModal: false,
      inventoryModal: false,

      //tableFields
      doDetailFields: [{
          key: 'material',
          label: 'Material',
          filterable: true,
          sortable: true
        },
        {
          key: 'desc',
          label: 'Description',
          filterable: true,
          sortable: true
        },
        {
          key: 'lineitem',
          label: 'Line Item',
          filterable: true,
          sortable: true
        },
        {
          key: 'qty',
          label: 'Quantity',
          filterable: true,
          sortable: true
        }
      ],
      inventoryFields: [
        {
          key: "itemModel",
          label: "Model",
          filterable: true,
          sortable: true
        },
        {
          key: "sapDo",
          label: "DO Number",
          filterable: true,
          sortable: true
        },
        {
          key: "barcode",
          label: "Barcode",
          filterable: true,
          sortable: true
        },
        {
          key: "serial",
          label: "Serial",
          filterable: true,
          sortable: true
        },
        {
          key: "createdAt",
          label: "DO Date",
          filterable: true,
          sortable: true,
        },
      ],

      //button disable
      disableButton: false,
      tableLoading: false,

      //data references
      itemCatalog: [],

    };
  },
  created() {
    document.title = "Manual Create Delivery Order| RSP";
    //get current dealer
  },
  mounted() {
    let now = new Date();
    this.today = Number.parseInt(String(now.getDate()).padStart(2, "0"));

  },
  methods: {
    ...mapActions({
      getAllInventories: 'inventory/getAllInventories'
    }),
    getItem(data) {
      var searchItem = this.itemCatalog.find((x) => {
        return x._id == data;
      });
      var result;
      if (searchItem === undefined) {
        result = {
          itemModel: " ",
        };
      } else {
        result = searchItem;
      }
      return result;
    },
    getItems() {
      var idList = this.currentDo.DO_details.map((x) => {
        return x.material;
      });
      //temporary get api items catalog from axios
      var list = idList.join("|");
      console.info("getItems list ", list);
      axios
        .get("sales-and-purchase/drcc/items/multi?idList=" + list)
        .then((response) => {
          this.itemCatalog = response.data.data;
          this.delay = false
          // console.log(this.itemCatalog)
        })
        .catch((e) => {
          this.$bvToast.toast("Load data failed, please reload the page.", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
          console.log(e);
        });
    },
    doRefresh() {
      this.currentDo = {
        deliveryDate: 0,
        DO_details: []
      }
      this.inventoryList = []
      this.doSearch = ""
    },
    dates(date) {
      return dateFormat(date);
    },
    searchDoByNumber() {
      if(this.doSearch==""){
        this.$bvToast.toast("Please insert DO Number to Search.", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
        return;
      }
      this.getDeliveryOrderByDO(this.doSearch)
    },
    getDeliveryOrderByDO(sapDo) {
      this.$store.dispatch("delivery/getDeliveryOrderByDoNumber", sapDo).then((x) => {
        console.info(x)
        if(x.length<=0){
          this.$bvToast.toast("DO Number "+sapDo+" not found", {
            title: "Warning",
            variant: "danger",
            solid: true,
          });
        return;
        }
        this.currentDo = x[0];
        this.getItems();
        this.getInventoryList();
      })
    },
    getInventoryList() {
      this.tableLoading = true;

      if (this.currentDo.sapDo != undefined) {
        this.getAllInventories({
          sapDo: this.currentDo.sapDo,
          entry: 10000
        }).then((data) => {
          this.inventoryList = data.data;
          this.tableLoading = false;
        });
      }

    },
    /** Delivery Order */
    previewFilesDO(e) {
      this.errorMessage = "";
      let files = e.target.files,
        f = files[0];
      console.info("file ", f)
      const reader = new FileReader();
      reader.onload = function (e) {
        console.info('after load ', e)
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: "array"
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const XL_row_object = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
        });
        console.info('xl row  ', XL_row_object)
        if (XL_row_object[0] == undefined) {
          this.errorMessage = "Error while trying to upload do data"
          throw new Error("Error while trying to upload do data")
        }
        var doData = XL_row_object[0];
        var orderDateTemp;
        var deliveryDateTemp;
        var yearTemp;
        var monthTemp;
        var dateTemp;

        if (doData.ORDDATE == '' || doData.ORDDATE == null) {
          orderDateTemp = new Date(0)
        } else {
          //format is yyyymmdd
          yearTemp = parseInt(doData.ORDDATE.substring(0, 4));
          monthTemp = parseInt(doData.ORDDATE.substring(4, 6)) - 1;
          dateTemp = parseInt(doData.ORDDATE.substring(6, 8))
          orderDateTemp = new Date(yearTemp, monthTemp, dateTemp)
        }
        if (doData.DELDATE == '' || doData.DELDATE == null) {
          deliveryDateTemp = new Date(0)
        } else {
          //format is yyyymmdd
          yearTemp = parseInt(doData.DELDATE.substring(0, 4));
          monthTemp = parseInt(doData.DELDATE.substring(4, 6)) - 1;
          dateTemp = parseInt(doData.DELDATE.substring(6, 8))
          deliveryDateTemp = new Date(yearTemp, monthTemp, dateTemp)
        }

        this.doUpload = {
          //deliveryPostalCode: doData.SPCODE.trim(),
          //soType:doData.SOTYPE.trim(),
          //CustomerName1:doData.SNAME1,
          //CustomerName2:doData.SNAME2,
          //CustomerName3:doData.SNAME3,
          //CustomerName4:doData.SNAME4,
          //deliveryAddress1:doData.SADDR1+""+doData.SADDR2+""+doData.SADDR3,
          //deliveryAddress2:doData.SADDR4,
          //deliveryAddress3:doData.SADDR5,
          shippingPoint:doData.SHPPNT.trim(),
          pcode:doData.PCODE.trim(),
          //sapCreatedBy:doData.CRBY.trim(),
          priority: doData.PRIORTY.trim(),
          plant: doData.PLANT.trim(),
          sapDo: doData.DONO.trim(),
          sapSo: doData.SONO.trim(),
          doType: doData.DOTYPE.trim(),
          status: "Fulfilled",
          updatedBy: "RSP-SYSTEM",
          dealerNo: doData.CUSTNO.trim(),
          orderDate: orderDateTemp,
          deliveryDate: deliveryDateTemp,
          distributionChannel: doData.SHIPVIA,
          DO_details: []
        }
        console.info("DO to be uploaded ", this.doUpload)

      }.bind(this);
      reader.readAsArrayBuffer(f);
    },
    uploadDataDO() {
      axios.post('sales-and-purchase/drcc/create-manual/upload-do', this.doUpload).then((response) => {
          this.currentDo = response.data.data
          this.getDeliveryOrderByDO(this.currentDo.sapDo)

          this.$bvToast.toast("Create Delivery Order Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          })
          this.disableButton = false;
          this.doModal = false;
        })
        .catch((e) => {
          this.disableButton = false;
          this.$bvToast.toast(e.response.data.errors ? e.response.data.errors[0].msg : e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          })
        })
    },
    /** Delivery Order Detail */
    previewFilesDODetails(e) {
      this.errorMessage = "";
      this.errorList = [];
      this.doDetailUploadList = [];
      let files = e.target.files,
        f = files[0];
      console.info("file ", f)
      const reader = new FileReader();
      reader.onload = function (e) {
        console.info('after load ', e)
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: "array"
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const XL_row_object = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
        });
        console.info('xl row  ', XL_row_object)
        if (XL_row_object[0] == undefined) {
          this.errorMessage = "Error while trying to upload do data"
          throw new Error("Error while trying to upload do data")
        }
        //DOLNNO,DONO,MMATNO,QTY
        var curDoDetail;
        for (var x = 0; x < XL_row_object.length; x++) {
          curDoDetail = XL_row_object[x];
          if (curDoDetail.DONO !== this.currentDo.sapDo) {
            this.errorList.push("Item with wrong DO is found, row: " + x + " DO: " + curDoDetail.DONO);
            continue;
          }
          if (curDoDetail.MATNO == undefined) {
            this.errorList.push("Item has missing MATNO, row: " + x);
            continue;
          }
          if (curDoDetail.QTYOR == undefined) {
            this.errorList.push("Item has missing QTYOR, row: " + x);
            continue;
          }
          if (curDoDetail.DOLNNO == undefined) {
            this.errorList.push("Item has missing DOLNNO, row: " + x);
            continue;
          }
          this.doDetailUploadList.push({
            material: curDoDetail.MATNO.trim(),
            qty: curDoDetail.QTYOR.trim(),
            lineitem: curDoDetail.DOLNNO.trim()
          })
        }

        console.info("DO to be uploaded ", this.doDetailUploadList)

      }.bind(this);
      reader.readAsArrayBuffer(f);
    },
    uploadDoDetail() {
      axios.post('sales-and-purchase/drcc/create-manual/upload-do-detail/' + this.currentDo.sapDo,
          this.doDetailUploadList).then((response) => {
          this.getDeliveryOrderByDO(this.currentDo.sapDo)

          this.$bvToast.toast("Create Delivery Order Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          })
          this.disableButton = false;
          this.doDetailModal = false;
        })
        .catch((e) => {
          this.disableButton = false;
          this.$bvToast.toast(e.response.data.errors ? e.response.data.errors[0].msg : e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          })
        })
    },
    /** Inventory */
    previewFilesInventory(e) {
      this.errorMessage = "";
      this.errorList = [];
      this.inventoryUploadList = [];
      let files = e.target.files,
        f = files[0];
      console.info("file ", f)
      const reader = new FileReader();
      reader.onload = function (e) {
        console.info('after load ', e)
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, {
          type: "array"
        });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const XL_row_object = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          defval: "",
        });
        console.info('xl row  ', XL_row_object)
        console.info('xl row  ', XL_row_object.length)
        if (XL_row_object[0] == undefined) {
          this.errorMessage = "Error while trying to upload do data"
          throw new Error("Error while trying to upload do data")
        }
        //DOLNNO,DONO,MMATNO,QTY
        var curInventory;
        for (var x = 0; x < XL_row_object.length; x++) {
          curInventory = XL_row_object[x];
          if (curInventory.DONO !== this.currentDo.sapDo) {
            this.errorList.push("Inventory with wrong DO is found, row: " + x + " DO: " + curInventory.DONO);
            continue;
          }
          if (curInventory.MATNO == undefined) {
            this.errorList.push("Inventory has missing MATNO, row: " + x);
            continue;
          }
          if (curInventory.SERNO == undefined) {
            this.errorList.push("Inventory has missing SERNO, row: " + x);
            continue;
          }
          if (curInventory.serial == undefined) {
            this.errorList.push("Inventory has missing regno, row: " + x);
            continue;
          }
          if (curInventory.SERNO.trim() == "EMPTY" || curInventory.SERNO.trim().length!=14) {
            this.errorList.push("invalid SERNO, row: " + x);
            continue;
          }
          if (curInventory.serial.trim() == "EMPTY" || curInventory.serial.trim().length!=8) {
            this.errorList.push("invalid regno, row: " + x);
            continue;
          }
          console.info('this push ', this.inventoryUploadList)
          this.inventoryUploadList.push({
            sapDo:curInventory.DONO.trim(),
            certNo: curInventory.regno.trim(),
            itemModel: curInventory.MATNO.trim(),
            barcode: curInventory.SERNO.trim(),
            serial: curInventory.serial.trim()
          })
        }

        console.info("Inventory List ", this.inventoryUploadList)

      }.bind(this);
      reader.readAsArrayBuffer(f);
    },
    uploadInventory() {
      axios.post('sales-and-purchase/drcc/create-manual/upload-inventory/' + this.currentDo.sapDo,
          this.inventoryUploadList).then((response) => {
          this.getDeliveryOrderByDO(this.currentDo.sapDo)
          this.$bvToast.toast("Create Inventory Successfully", {
            title: "Success",
            variant: "success",
            solid: true,
          })
          this.disableButton = false;
          this.inventoryModal = false;
        })
        .catch((e) => {
          this.disableButton = false;
          this.$bvToast.toast(e.response.data.errors ? e.response.data.errors[0].msg : e.response.data.message, {
            title: "Failed",
            variant: "danger",
            solid: true,
          })
        })
    }

  },
  computed: {
    doDetailCanUpload() {
      return (this.errorList.length == 0) && (this.doDetailUploadList.length > 0)
    },
    inventoryCanUpload() {
      return (this.errorList.length == 0) && (this.inventoryUploadList.length > 0)
    },
    uploadEnabled() {
      var enabled = {
        do: false,
        doDetail: false,
        inventory: false
      }
      if (this.currentDo.sapDo == undefined) {
        enabled.do = true

      } else {
        enabled.doDetail = true
        enabled.inventory = true
      }

      return enabled
    },
    permission() {
      let result = userAccess("Manual Create By CSV", "manualcreate_menu")
      return result
    }
  },
};
</script>

<style>
</style>
